import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';

import { definePreset } from '@primevue/themes';
import Lara from '@primevue/themes/lara';

const colorAzureRadiance100: string = '#0094ff';
const colorScienceBlue100: string = '#006bb8';
const colorMineShaft100: string = '#222';
const colorMillionGrey100: string = '#999';
const colorLighthouse100: string = '#f4f4f4';

const colorBlack100: string = '#000000';
const colorBlack20: string = '#00000033';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const colorBlack10: string = '#0000001A';
const colorWhite100: string = '#fff';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const colorBaslerOrange: string = '#f59d21';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const colorBaslerOrangeDarker: string = '#d17f06';
const colorBaslerOrangeRed: string = '#ff5252';

const BaslerPreset = definePreset(Lara, {
  semantic: {
    primary: {
      50: '{blue.50}',
      100: '{blue.100}',
      200: '{blue.200}',
      300: '{blue.300}',
      400: '{blue.400}',
      500: '{blue.500}',
      600: '{blue.600}',
      700: '{blue.700}',
      800: '{blue.800}',
      900: '{blue.900}',
      950: '{blue.950}'
    },
    focusRing: {
      width: '2px',
      offset: '1px',
      color: colorAzureRadiance100,
      ringShadow: '0 0 0 0.1rem ' + colorAzureRadiance100
    },
    colorScheme: {
      light: {
        primary: {
          color: colorAzureRadiance100,
          inverseColor: colorWhite100,
          hoverColor: colorScienceBlue100,
          activeColor: colorAzureRadiance100
        },
        formField: {
          borderColor: colorMillionGrey100,
          hoverBorderColor: colorBlack20,
          focusBorderColor: colorAzureRadiance100,
          invalidBorderColor: colorBaslerOrangeRed
        }
      },
      dark: {
        primary: {
          color: colorWhite100,
          inverseColor: colorBlack100,
          hoverColor: colorLighthouse100,
          activeColor: colorLighthouse100
        },
        formField: {
          borderColor: colorWhite100,
          hoverBorderColor: colorLighthouse100,
          focusBorderColor: colorAzureRadiance100,
          invalidBorderColor: colorBaslerOrangeRed
        }
      }
    }
  },
  components: {
    checkbox: {
      background: 'none',
      checkedBackground: colorAzureRadiance100,
      checkedHoverBackground: colorScienceBlue100,
      checkedBorderColor: colorAzureRadiance100,
      checkedHoverBorderColor: colorScienceBlue100,
      iconSize: '14px',
      iconCheckedColor: colorWhite100,
      iconCheckedHoverColor: colorWhite100,
      css: () => `
      .theme-dark label.p-label {
        color: ${colorWhite100}
      }
      label.p-label {
        font-size: 0.9rem;
        line-height: 1.1rem;
      }
      `,
      colorScheme: {
        light: {
          borderColor: colorMillionGrey100,
          hoverBorderColor: colorBlack20
        },

        dark: {
          borderColor: colorWhite100,
          hoverBorderColor: colorAzureRadiance100
        }
      }
    },
    radiobutton: {
      background: 'none',
      checkedBackground: 'none',
      checkedHoverBackground: 'none',
      checkedBorderColor: colorAzureRadiance100,
      checkedHoverBorderColor: colorScienceBlue100,
      iconSize: '12px',
      iconCheckedColor: colorAzureRadiance100,
      iconCheckedHoverColor: colorScienceBlue100,
      css: () => `
      .theme-dark label.p-label {
        color: ${colorWhite100}
      }
      label.p-label {
        font-size: 0.9rem;
        line-height: 1.1rem;
      }
      `,
      colorScheme: {
        light: {
          borderColor: colorMillionGrey100,
          borderHoverColor: colorBlack20
        },

        dark: {
          borderColor: colorWhite100,
          borderHoverColor: colorMillionGrey100
        }
      }
    },
    select: {
      filledBackground: colorLighthouse100,
      filledFocusBackground: colorLighthouse100,
      filledHoverBackground: colorLighthouse100,
      borderRadius: '8px',
      focusBorderColor: colorAzureRadiance100,
      hoverBorderColor: colorAzureRadiance100,
      focusRingShadow: '0 0 0 0.1rem ' + colorAzureRadiance100,
      dropdownWidth: '30px',
      css: () => `
      .p-variant-filled {
        border: 2px solid ${colorLighthouse100}
      }
      `,
      optionSelectedBackground: colorAzureRadiance100,
      optionSelectedColor: colorWhite100,
      optionSelectedFocusBackground: colorScienceBlue100,
      optionSelectedFocusColor: colorWhite100,
      optionFocusBackground: colorScienceBlue100,
      optionFocusColor: colorWhite100,
      optionBorderRadius: '8px',
      optionPadding: '5px 10px',
      overlayBorderRadius: '8px',
      overlayBackground: colorLighthouse100
    },
    slider: {
      trackBackground: colorBlack20,
      rangeBackground: colorAzureRadiance100,
      trackSize: '7px',
      handleContentHoverBackground: colorWhite100,
      handleContentWidth: '14px',
      handleContentHeight: '14px',
      css: () => `
      .p-invalid .p-slider-range {
        background-color: ${colorBaslerOrangeRed};
      }
      `
    },
    floatlabel: {
      color: colorMillionGrey100,
      focusColor: colorMineShaft100
    },
    inputtext: {
      borderColor: colorMillionGrey100,
      hoverBorderColor: colorBlack20,
      focusBorderColor: colorAzureRadiance100,
      focusRingShadow: '0 0 0 0.1rem ' + colorAzureRadiance100,
      placeholderColor: colorMillionGrey100,
      disabledBackground: colorLighthouse100,
      invalidBorderColor: colorBaslerOrangeRed,
      css: () => `
      .p-inputtext.p-invalid {
        box-shadow: 0 0 0 0.1rem ${colorBaslerOrangeRed};
      }
      `
    },
    inputnumber: {
      buttonWidth: '20px'
    },
    tieredmenu: {
      borderRadius: '20px',
      listPadding: '20px',
      listGap: '2',
      itemPadding: '5px',
      itemGap: '2'
    },
    css: () => `
      label.p-label {
        font-size: 0.9rem;
        line-height: 1.1rem;
      },
      `
  }
});

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(PrimeVue, {
    // Default theme configuration
    theme: {
      preset: BaslerPreset,
      options: {
        prefix: 'p',
        darkModeSelector: '.theme-dark',
        cssLayer: false
      }
    }
  });

  // adds directive v-tooltip
  nuxtApp.vueApp.directive('tooltip', Tooltip);
});
