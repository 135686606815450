import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _01_locale_client_iCVXuKfRxRsAvjEWLOc7MR6_2Y_fkwE4FaYZLSd0FhY from "/app/plugins/01.locale.client.ts";
import _02_globalPageSettings_client_qsiKiy1800BzvcjIyo1dtpxf0Aqb2muumIGkdrZVj70 from "/app/plugins/02.globalPageSettings.client.ts";
import contentfulLivePreview_client_SQB2tQWAz40kkEBdr_yLHA4D8iJIKTuk5aaOKv4nwFU from "/app/plugins/contentfulLivePreview.client.ts";
import session_client_jBJylGz6yTfXfQZFg5jY3S3yee_RP8UNvHx8yFpm2_k from "/app/plugins/session.client.ts";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_7DB4Q_rSg7Y6_r2xTJAR9sj0Plzl7GeeI8C3uqDzeqY from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import formkitPlugin_Kw9Zxpc32ie3g9uaRfiavVL004WiVJNxPefwwMCJMnU from "/app/.nuxt/formkitPlugin.mjs";
import _00_logger_client_av1SoPjMe3iMbX4ahN5lH6x9Ksmub5MY7PfmRkr1SV8 from "/app/plugins/00.logger.client.ts";
import _03_pageSettings_mhACTNKzNljOQp_qbulpW1lg7fND_ne_AG82_r_eIM0 from "/app/plugins/03.pageSettings.ts";
import _04_dictionary_client_yWYTtDySPsUmzNlUJ55zvY32imFjPS9V_l72bt8Tfy8 from "/app/plugins/04.dictionary.client.ts";
import _05_modals_E4ZQVLBfoRH2dfuwXesed8gz_aW8b4nO9F2SXHBXHXc from "/app/plugins/05.modals.ts";
import _06_env_SzOUj2SWvWb0ODUG6IH0DIuwf6Q9AqOPy5hczD8HINw from "/app/plugins/06.env.ts";
import _09_pinia_ntBbALvbwGSGTGLjJXblTfSHjjQ4M8WFIChQkzSAVS0 from "/app/plugins/09.pinia.ts";
import _10_shop_fEA9sgnEPyP41wTuUDU5DcPGCnWGiP4WKE70LYDpw5M from "/app/plugins/10.shop.ts";
import _11_audiences_V429WHpMflhj9jSUs2znLePQgiaKEV_tGNT5BJdnOyg from "/app/plugins/11.audiences.ts";
import _12_secondaryNavigation_XFjHCzF3flgY4MDqFw8UEFKMW49SHMNYeyrBKiMXfVs from "/app/plugins/12.secondaryNavigation.ts";
import _30_analytics_client_ZCxlMUwff220euyUjEbqKz9sxLXSY2VtScc_J757_S0 from "/app/plugins/30.analytics.client.ts";
import _40_breakpoints_client_ZaOLtuylE3I_4iUiOKmdomMO9DWJc4p_CCWLOuzVvds from "/app/plugins/40.breakpoints.client.ts";
import _41_globalCartStore_client_U_y_OkjIVS3b7yHq4hA_Uf_m6aq5GW26ip1Q2LBwzmw from "/app/plugins/41.globalCartStore.client.ts";
import _50_vue_toastification_DRQmxO9yeJT5xHArbtgac7LI4T3s7O1GKDrOxiDEX2w from "/app/plugins/50.vue-toastification.ts";
import _51_primevue_tvXtLqgyfs_DUrd4wvU6u93DICzk9rIdYG2RY1A4WnI from "/app/plugins/51.primevue.ts";
import _52_tippy_8K4NTBE_Lo3beZ393baKozM9EoxATsULSlq4zMpMfNw from "/app/plugins/52.tippy.ts";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _01_locale_client_iCVXuKfRxRsAvjEWLOc7MR6_2Y_fkwE4FaYZLSd0FhY,
  _02_globalPageSettings_client_qsiKiy1800BzvcjIyo1dtpxf0Aqb2muumIGkdrZVj70,
  contentfulLivePreview_client_SQB2tQWAz40kkEBdr_yLHA4D8iJIKTuk5aaOKv4nwFU,
  session_client_jBJylGz6yTfXfQZFg5jY3S3yee_RP8UNvHx8yFpm2_k,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_7DB4Q_rSg7Y6_r2xTJAR9sj0Plzl7GeeI8C3uqDzeqY,
  plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk,
  formkitPlugin_Kw9Zxpc32ie3g9uaRfiavVL004WiVJNxPefwwMCJMnU,
  _00_logger_client_av1SoPjMe3iMbX4ahN5lH6x9Ksmub5MY7PfmRkr1SV8,
  _03_pageSettings_mhACTNKzNljOQp_qbulpW1lg7fND_ne_AG82_r_eIM0,
  _04_dictionary_client_yWYTtDySPsUmzNlUJ55zvY32imFjPS9V_l72bt8Tfy8,
  _05_modals_E4ZQVLBfoRH2dfuwXesed8gz_aW8b4nO9F2SXHBXHXc,
  _06_env_SzOUj2SWvWb0ODUG6IH0DIuwf6Q9AqOPy5hczD8HINw,
  _09_pinia_ntBbALvbwGSGTGLjJXblTfSHjjQ4M8WFIChQkzSAVS0,
  _10_shop_fEA9sgnEPyP41wTuUDU5DcPGCnWGiP4WKE70LYDpw5M,
  _11_audiences_V429WHpMflhj9jSUs2znLePQgiaKEV_tGNT5BJdnOyg,
  _12_secondaryNavigation_XFjHCzF3flgY4MDqFw8UEFKMW49SHMNYeyrBKiMXfVs,
  _30_analytics_client_ZCxlMUwff220euyUjEbqKz9sxLXSY2VtScc_J757_S0,
  _40_breakpoints_client_ZaOLtuylE3I_4iUiOKmdomMO9DWJc4p_CCWLOuzVvds,
  _41_globalCartStore_client_U_y_OkjIVS3b7yHq4hA_Uf_m6aq5GW26ip1Q2LBwzmw,
  _50_vue_toastification_DRQmxO9yeJT5xHArbtgac7LI4T3s7O1GKDrOxiDEX2w,
  _51_primevue_tvXtLqgyfs_DUrd4wvU6u93DICzk9rIdYG2RY1A4WnI,
  _52_tippy_8K4NTBE_Lo3beZ393baKozM9EoxATsULSlq4zMpMfNw
]