import type { RouteLocationPathRaw } from 'vue-router';
import { navigateTo } from 'nuxt/app';
import type { NavigateToOptions } from '#app/composables/router';

export function enhancedNavigateTo(
  destination: RouteLocationPathRaw,
  options: NavigateToOptions = {}
) {
  if (process.server) {
    // redirects need an absolute url to be crawable by google etc.
    // @see https://gcp.baslerweb.com/jira/browse/WEB2-2422
    const url = useRequestURL();
    const redirectUrl = new URL(destination.path, url.origin);

    return navigateTo(
      {
        ...destination,
        path: redirectUrl.toString()
      },
      {
        ...options,
        // must be external if the url is absolute
        // @see https://nuxt.com/docs/api/utils/navigate-to#options-optional
        external: true
      }
    );
  }

  if (destination.path.startsWith('http')) {
    // @see https://gcp.baslerweb.com/jira/browse/DBP-1904
    options.external = true;
  }

  return navigateTo(destination, options);
}
